var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"reference"},[_c('div',{staticClass:"reference_wrapper"},[_vm._m(0),_c('section',{staticClass:"reference_body"},[_c('div',{staticClass:"preface"},[_vm._m(1),_c('button',{attrs:{"type":"button"},on:{"click":_vm.deskUploadWay}},[_vm._v(" 축제 자료를 지금 업로드 할 수 없다면? "),_c('img',{attrs:{"src":require("@/assets/img/rectangle-37.png"),"alt":"축제 자료를 지금 업로드 할 수 없다면?"}})]),_c('button',{attrs:{"type":"button"},on:{"click":_vm.mobileUploadWay}},[_vm._v(" 축제 자료를 지금 업로드 할 수 없다면? "),_c('img',{attrs:{"src":require("@/assets/img/rectangle-37-black.png"),"alt":"축제 자료를 지금 업로드 할 수 없다면?"}})])]),_c('div',{staticClass:"download_box"},[_vm._m(2),_c('div',{staticClass:"download_btns"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.download(
                'docs/festival_info.doc',
                '스탬프 팝 페스티벌_축제정보_양식',
                'doc'
              )}}},[_vm._v(" 스탬프 팝 페스티벌_축제정보_양식.doc ")]),_c('button',{attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.download(
                'docs/festival_info.hwp',
                '스탬프 팝 페스티벌_축제정보_양식',
                'hwp'
              )}}},[_vm._v(" 스탬프 팝 페스티벌_축제정보_양식.hwp ")])])]),_vm._m(3),_c('div',{staticClass:"upload_box"},[_c('h3',[_vm._v("파일 업로드")]),_c('div',{staticClass:"upload_contents"},[_c('div',{staticClass:"info"},[_c('h4',[_vm._v("축제정보")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.info),expression:"info"}],attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.info)},on:{"input":function($event){if($event.target.composing){ return; }_vm.info=$event.target.value}}}),_c('input',{ref:"refInfo",attrs:{"type":"file","id":"festival_info"},on:{"change":function($event){return _vm.previewFile('info')}}}),_c('label',{attrs:{"for":"festival_info"}},[_vm._v("찾아보기")])]),_c('div',{staticClass:"picture"},[_c('h4',[_vm._v("사진자료")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.picture),expression:"picture"}],attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.picture)},on:{"input":function($event){if($event.target.composing){ return; }_vm.picture=$event.target.value}}}),_c('input',{ref:"refPicture",attrs:{"type":"file","id":"festival_picture"},on:{"change":function($event){return _vm.previewFile('picture')}}}),_c('label',{attrs:{"for":"festival_picture"}},[_vm._v("찾아보기")])])]),_c('p',[_vm._v("※ 용량 10M 이하만 업로드 가능합니다.")])]),_c('div',{staticClass:"btn_center"},[_c('base-button',{attrs:{"type":"primary","disabled":_vm.submitStatus === 'PENDING'},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.submitStatus === "PENDING" ? "등록중..." : "등록하기"))])],1)])]),_c('div',{staticClass:"mail_box"},[_c('div',{staticClass:"mail_box_contents"},[_vm._m(4),_c('button',{attrs:{"type":"button"},on:{"click":_vm.sendEmail}},[_vm._v("메일로 보내기")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section_header"},[_c('h1',[_vm._v("축제 자료 등록")]),_c('div',{staticClass:"product_header_contents"},[_c('div',[_c('span',[_vm._v("1")]),_c('p')]),_c('div',[_c('span',[_vm._v("2")]),_c('p',[_vm._v("축제 자료 등록")])]),_c('div',[_c('span',[_vm._v("3")]),_c('p')])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("축제정보")]),_c('p',[_vm._v("아래의 양식을 다운받아 작성한 후 파일을 업로드해주세요.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("양식 다운받기")]),_c('a',{attrs:{"href":"/docs/festival_info_v1.2.pdf","download":"스탬프 팝 페스티벌_축제정보_작성예시"}},[_vm._v("작성예시 보기")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"description"},[_c('h3',[_vm._v("축제사진자료")]),_c('div',{staticClass:"description_top"},[_c('p',[_vm._v("- 축제 포스터 이미지 (1장)")]),_c('p',[_vm._v("- 축제 소개 사진 (최대 4장)")]),_c('p',[_vm._v(" - 스탬프 제작용 참고 사진 (스탬프 개수에 맞춰 등록, 최대 10개) ")])]),_c('div',{staticClass:"description_bottom"},[_c('p',[_vm._v("※ 사진파일을 압축하여 하나의 파일로 업로드 해주세요.")]),_c('p',[_vm._v("※ 스탬프 제작용 참고사진은 꼭 파일명에 장소명을 적어주세요.")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("파일 등록이 안되거나, 용량이 큰 경우 메일로 발송해주세요.")]),_c('p',[_vm._v("이메일 : festival@nextinnovation.kr")])])}]

export { render, staticRenderFns }